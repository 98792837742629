import React, {useEffect, useCallback} from "react";
import { useParams } from "react-router-dom";
import "./App.css";

const SendWeb = () => {
  const { email } = useParams(); // Obtén el valor de "email" de la URL

  const handleSendEmailClick = () => {
    const link = document.createElement("a");
    link.href = email;
    link.target = "_blank";
    link.click();
  };

  useEffect(() => {
    handleSendEmailClick();
  }, [handleSendEmailClick]);

  return (
    <div className="centered-container">
      <button onClick={handleSendEmailClick}>Abrir url</button>
    </div>
  );
};

export default SendWeb;
