import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCheck, FaLink } from "react-icons/fa";
import firebase from "./firebase";
import "./App.css";
import CopyIcon from "./IconoCopiar";

function WidgetPage() {
  const { widgetId } = useParams();
  const [widgetData, setWidgetData] = useState(null);

  const [copiedButtonId, setCopiedButtonId] = useState(null);

  useEffect(() => {
    const widgetRef = firebase.database().ref(`widgets/${widgetId}`);

    widgetRef.on("value", (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setWidgetData(data);
      } else {
        setWidgetData(null);
      }
    });

    return () => {
      widgetRef.off("value");
    };
  }, [widgetId]);

  const handleCopyButtonClick = (value, buttonId) => {
    const inputElement = document.createElement("input");
    inputElement.value = value;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand("copy");
    document.body.removeChild(inputElement);

    setCopiedButtonId(buttonId);
    setTimeout(() => {
      setCopiedButtonId(null);
    }, 1500);
  };

  const handleButtonLinkClick = (button) => {
    console.log(button);
    window.open(button.url, "_blank");
  };

  const openLinkInNewTab = (url) => {
    //window.open(url, '_blank');
    window.open(url, "_blank", "noopener noreferrer");
  };

  const handleButtonEmailClick = (mailtoLink) => {
    const [to, params] = mailtoLink.split("?");
    const paramsMap = {};

    if (params) {
      const paramPairs = params.split("&");
      paramPairs.forEach((paramPair) => {
        const [key, value] = paramPair.split("=");
        paramsMap[key] = decodeURIComponent(value);
      });
    }

    const emailStart = "mailto:";
    const startIndex = to.indexOf(emailStart) + emailStart.length;
    const email = to.substring(startIndex);

    const mailtoURL = `mailto:${email}?subject=${
      paramsMap.subject || ""
    }&body=${paramsMap.body || ""}`;
    window.open(mailtoURL, "_blank");
  };

  function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Comprueba si el User Agent contiene "iPhone" o "Android"
    if (/iPhone|iPod|iPad/.test(userAgent) || /Android/.test(userAgent)) {
      return true; // Es un dispositivo móvil
    }

    return false; // No es un dispositivo móvil
  }

  return (
    <div className="container centered-content">
      {widgetData ? (
        !widgetData.inverted ? (
          <div>
            <div className="urls">
              {widgetData.urls &&
                widgetData.urls.map((url, index) => (
                  <div className="link-container">
                    <span className="link-title">{url.title}</span>
                    <div className="link-align">
                      <div key={url.title} className="input-row">
                        <FaLink />
                        <input
                          type="text"
                          value={url.url}
                          readOnly
                          className={`styled-input ${
                            url.url ? "" : "no-value"
                          }`}
                        />
                      </div>
                      <button
                        onClick={() =>
                          handleCopyButtonClick(url.url, url.title)
                        }
                        className={`styled-button styled-button-extra ${
                          copiedButtonId === url.title ? "copied" : ""
                        }`}
                      >
                        {copiedButtonId === url.title ? (
                          <>
                            <FaCheck /> Copiado!
                          </>
                        ) : (
                          <>
                            <CopyIcon /> Copiar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="buttons buttons-bottom">
              {widgetData.buttons &&
                widgetData.buttons.map((button, index) => (
                  <div key={button.title} className="button-row">
                    {button.url.startsWith("mailto:") ? (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          const email = button.url;
                          if (isMobile()) {
                            const emailUrl = `${
                              window.location.origin
                            }/send-email/${encodeURIComponent(email)}`;
                            console.log("Email URL:", emailUrl);
                            openLinkInNewTab(emailUrl); // Abre emailUrl en una nueva pestaña
                          } else {
                            window.open(email, "_blank");
                          }
                        }}
                      >
                        <button
                          className={`styled-button social ${
                            button.color2 ? "gradient-button" : ""
                          }`}
                          style={{
                            background: button.color2
                              ? `linear-gradient(to right, ${
                                  button.color || "#007bff"
                                }, ${button.color2})`
                              : button.color || "#007bff",
                          }}
                        >
                          {button.icon && (
                            <img
                              height={20}
                              width={20}
                              src={button.icon}
                              alt={button.text}
                              style={{
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          <span style={{ verticalAlign: "middle" }}>
                            {button.text}
                          </span>
                        </button>
                      </a>
                    ) : (
                      <a href={button.url} target="_blank" rel="noopener noreferrer">
                        <button
                          className={`styled-button social ${
                            button.color2 ? "gradient-button" : ""
                          }`}
                          style={{
                            background: button.color2
                              ? `linear-gradient(to right, ${
                                  button.color || "#007bff"
                                }, ${button.color2})`
                              : button.color || "#007bff",
                          }}
                        >
                          {button.icon && (
                            <img
                              height={20}
                              width={20}
                              src={button.icon}
                              alt={button.text}
                              style={{
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          <span style={{ verticalAlign: "middle" }}>
                            {button.text}
                          </span>
                        </button>
                      </a>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          // INVERTED
          <div>
            <div className="buttons">
              {widgetData.buttons &&
                widgetData.buttons.map((button, index) => (
                  <div key={button.title} className="button-row">
                    {button.url.startsWith("mailto:") ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();

                          const email = button.url;
                          if (isMobile()) {
                            const emailUrl = `${
                              window.location.origin
                            }/send-email/${encodeURIComponent(email)}`;
                            console.log("Email URL:", emailUrl);
                            openLinkInNewTab(emailUrl); // Abre emailUrl en una nueva pestaña
                          } else {
                            window.open(email, "_blank");
                          }
                        }}
                      >
                        <button
                          className={`styled-button social ${
                            button.color2 ? "gradient-button" : ""
                          }`}
                          style={{
                            background: button.color2
                              ? `linear-gradient(to right, ${
                                  button.color || "#007bff"
                                }, ${button.color2})`
                              : button.color || "#007bff",
                          }}
                        >
                          {button.icon && (
                            <img
                              height={20}
                              width={20}
                              src={button.icon}
                              alt={button.text}
                              style={{
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          <span style={{ verticalAlign: "middle" }}>
                            {button.text}
                          </span>
                        </button>
                      </a>
                    ) : (
                      <a href={button.url} target="_blank" rel="noopener noreferrer">
                        <button
                          className={`styled-button social ${
                            button.color2 ? "gradient-button" : ""
                          }`}
                          //onClick={() => handleButtonLinkClick(button)}
                          style={{
                            background: button.color2
                              ? `linear-gradient(to right, ${
                                  button.color || "#007bff"
                                }, ${button.color2})`
                              : button.color || "#007bff",
                          }}
                        >
                          {button.icon && (
                            <img
                              height={20}
                              width={20}
                              src={button.icon}
                              alt={button.text}
                              style={{
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          <span style={{ verticalAlign: "middle" }}>
                            {button.text}
                          </span>
                        </button>
                      </a>
                    )}
                  </div>
                ))}
            </div>
            <div className="urls urls-bottom">
              {widgetData.urls &&
                widgetData.urls.map((url, index) => (
                  <div className="link-container link-container-bottom">
                    <span className="link-title">{url.title}</span>
                    <div className="link-align">
                      <div key={url.title} className="input-row">
                        <FaLink />
                        <input
                          type="text"
                          value={url.url}
                          readOnly
                          className={`styled-input ${
                            url.url ? "" : "no-value"
                          }`}
                        />
                      </div>
                      <button
                        onClick={() =>
                          handleCopyButtonClick(url.url, url.title)
                        }
                        className={`styled-button styled-button-extra ${
                          copiedButtonId === url.title ? "copied" : ""
                        }`}
                      >
                        {copiedButtonId === url.title ? (
                          <>
                            <FaCheck /> Copiado!
                          </>
                        ) : (
                          <>
                            <CopyIcon /> Copiar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      ) : (
        <p></p>
      )}
    </div>
  );
}

export default WidgetPage;