import React, {useEffect, useCallback} from "react";
import { useParams } from "react-router-dom";
import "./App.css";

const SendEmail = () => {
  const { email } = useParams(); // Obtén el valor de "email" de la URL

  const handleSendEmailClick = useCallback(() => {
    // Utiliza window.open para abrir el cliente de correo
    window.open(email, "_self");
  }, [email]);

  useEffect(() => {
    handleSendEmailClick();
  }, [handleSendEmailClick]);

  return (
    <div className="centered-container">
      <button onClick={handleSendEmailClick}>Abrir cliente de correo</button>
    </div>
  );
};

export default SendEmail;
